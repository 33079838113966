// copied from 'backend.js'

import { Instruction } from "./renewals"

function parseBackend(promise: Promise<Response>) {
    return promise.then(res => {
        //console.log(res)
        return res.ok
            ? res.json()
            : (res.status >= 500)
                ? Promise.reject({ status: "unreachable", message: `${res.status}: Server is unreachable` })
                : Promise.reject({ status: "unauthorized", message: `${res.status}: You are not authorized` })
    }).then(res =>
        (res.status !== "ok")
            ? Promise.reject(res) // .status + ": " + res.message)
            : res.payload ?? {})
}


export function rpc(request) {
    return parseBackend(fetch(`/api/dennemeyer/${request.entity}`, {
        method: 'POST',
        body: JSON.stringify(request),
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json'},
    }))
}

export function synchronize(ipRightIds: number[] = []) {
    const ids = ipRightIds.length === 0
        ? ''
        : '?' + ipRightIds.map(id => `ipRightId=${id}`).join('&')
    return parseBackend(fetch('/api/dennemeyer/synchronize' + ids))
}

export function get_entities(entities) {
    return parseBackend(fetch(`/api/dennemeyer/${entities}`))
}

export function post_instructions(instructions: {DennemeyerId: string, Instruction: Instruction}[]) {
    return pass_through('/v1.2/api/renewals/instructions/by-id', 'POST', instructions)
}

export async function pass_through(path, method = 'GET', body = undefined) {
    const options = method === 'GET'
        ? {} 
        : method === 'DELETE'
        ? { method }
        : { 
            method,
            body: body ? JSON.stringify(body) : undefined,
            credentials: 'same-origin' as RequestCredentials,
            headers: { 'Content-Type': 'application/json'},
        }
    return parseBackend(fetch('/api/dennemeyer/pass-through' + path, options))
}

// PostIpRight
export async function post_ip_right_to_dennemeyer(ipRight) {
    return parseBackend(fetch('/api/dennemeyer/import', {
        method: 'POST',
        body: JSON.stringify(ipRight),
        credentials: 'same-origin' as RequestCredentials,
        headers: { 'Content-Type': 'application/json' },
    }))
}


// TODO: make token available in context
export async function download_document(dennemeyerId, documentName) {
    // HTTP/1.1 400 Bad Request
    // {"Errors":["The value 'xx' is not valid."],"HasError":true}
    return parseBackend(fetch('/api/dennemeyer/token'))
        .then(({token, baseUrl}) => 
            fetch(`https://${baseUrl}/v1.2/api/documents/by-id/${dennemeyerId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }})
                .then(response =>
                    (response.ok
                        ? response.blob()
                        : response.json().then(json => Promise.reject(
                            { status: "fail", message: json.Errors[0] ?? "Unknown error" }))
                    ).then(blob => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                        //var _url = window.URL.createObjectURL(blob);
                        //window.open(_url, "_blank")
                        const filename = documentName
                        const urlObj = window.URL.createObjectURL(blob)
                        const a = document.createElement('a')
                        a.href = urlObj
                        a.download = filename
                        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click()
                        a.remove()  //afterwards we remove the element again         
                    }))
        )
}

export interface SubscriptionProps {
    clientId: string;
    eventType?: string
    notificationRecipient?: string;
    notificationId: string;
    host: string;
}

// TODO: get host and port
export async function save_subscription(subscription: SubscriptionProps) {
    // '"https://dev.patent-cockpit.com/api/dennemeyer-notifications?eventId=%7BEventId%7D&eventType=%7BEventType%7D&ipRightId=%7BIpRightDennemeyerId%7D&maintenanceActionId=%7BMaintenanceActionDennemeyerId%7D&notificationId=b1fb1db9-532a-44aa-839a-7369fb1bccb6"'
    const body = {
        ClientId: subscription.clientId,
        EventType: subscription.eventType,
        NotificationType: "WebHook", //"EMail", // or WebHook
        NotificationRecipient: `${subscription.host}/api/dennemeyer-notifications?eventId={EventId}&eventType={EventType}&ipRightId={IpRightDennemeyerId}&maintenanceActionId={MaintenanceActionDennemeyerId}&notificationId=${subscription.notificationId}`// subscription.notificationRecipient,
    }
    return pass_through('/v1.2/api/notifications/subscriptions', 'POST', body)
}

export async function delete_subscription(subscriptionId: number) {
    return pass_through(`/v1.2/api/notifications/subscriptions/by-id/${subscriptionId}`, 'DELETE')
}

export async function send_test_notification(host: string, eventType: string, ipRightDennemeyerId: string, notificationId: string) {
    const path = `/v1.2/api/notifications/createtestnotification/${eventType}/${ipRightDennemeyerId}`
    const options = {
        method: 'POST',
        body: `"${host}/api/dennemeyer-notifications?eventId={EventId}&eventType={EventType}&ipRightId={IpRightDennemeyerId}&maintenanceActionId={MaintenanceActionDennemeyerId}&notificationId=${notificationId}"`, // subscription.notificationRecipient,
        credentials: 'same-origin' as RequestCredentials,
        headers: { 'Content-Type': 'application/json' },
    }
    return parseBackend(fetch('/api/dennemeyer/pass-through' + path, options))
}

export type DennemeyerEntity = 'cost-centers' | 'owners' | 'addresses' | 'members'

export async function mark_as_dirty(type: DennemeyerEntity, id: number) {
    return parseBackend(fetch(`/api/dennemeyer/dirty/${type}/${id}`, { method: 'POST'}))
}

export async function get_api_status() {
    // https://api-sandbox.dennemeyer.com/docs/v1.2/index.html#tag/Administration/operation/GetApiStauts
    return pass_through('/v1.2/api/clientadministration/api-status')
}

export async function get_realm_statistics(start: string, end: string) {
    return parseBackend(fetch(`/api/dennemeyer/statistics?start=${start}&end=${end}`))
}