import { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useTranslation } from "react-i18next"
import { PiSidebarFill } from "react-icons/pi";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import clsx from "clsx";

import { UserMenu } from "./UserMenu";
import { useBackend } from "../BackendProvider";
import { TasksBadge } from "../tasks/TasksProvider";
import { RenewalsBadge } from "../renewal/components";
import { IconBurgerMenu, IconLightBulb, IconTradeMark, IconBox, IconShieldCheck, IconPatentCockpit, IconArrowPath, IconCalendar, IconDocumentText, IconChevronDown, IconChevronUp, IconCheckBadge, IconPhoto } from "../components/icons";
import { useRoles } from "../user/Auth";
import { useSingleTeamMangement } from "../Management";
import { ModernVersion } from "../components/Version";
import DefaultSearchButton from "../search/SearchButton";
import { AddFilter, FilterDisplay, FilterRibbon, OnlyActiveRibbon } from "../filter/FilterBar";
import { useFilter } from "../filter/Filter";
import { active_only_filter } from "../filter/utils";
import { useLocationHistory } from "../cockpit/Location";
import { MessagesDisplay } from ".";


const burgerMenuId = 'burger-menu'

export default function Layout() {
    const {t} = useTranslation()

    const { team, realmId } = useSingleTeamMangement()
    const {
        isDev, isEditUser, hasAnnuities, isAnnuityAdmin, hasInnovation, hasTasks, hasBrands, hasDocuments, 
        hasCosts, hasClaimScopes, /*hasProductCountries,*/ hasTracking, hasPortfolioUpdate, hasBulkEdit,
    } = useRoles()
    const { images } = useBackend()
    const { portfolioLink } = useLocationHistory()

    const [smallMenu, setSmallMenu] = useState(false)

    const navigationItems = [
        { to: "cockpit", icon: <IconPatentCockpit />, label: "Patent Cockpit" },
        { to: "patents", icon: <IconShieldCheck />, label: t("patents"), subitems: [
            { to: portfolioLink("/patents/portfolio", "portfolio"), label: t("portfolio") },
            { to: "country-family", label: t("country-map") },
            { to: "data", label: t("data-wizard") },
            { to: "products", label: t("product-mapping") },
            { to: "forecast", label: t("forecast.title") },
            hasCosts &&
            { to: "costs", label: t("costs") },
            { to: "evaluations", label: t("evaluations") },
            hasClaimScopes &&
            { to: "claim-scapes", label: t("claimscape") },
            hasPortfolioUpdate &&
            { to: "update-portfolio", label: t("portfolio-update") },
            hasBulkEdit && 
            { to: "bulk-edit", label: t("bulk-edit") },
        ] },
        hasInnovation && 
        { to: "inventions", icon: <IconLightBulb />, label: t("inventions"), subitems: [
            { to: portfolioLink("/inventions/portfolio", "portfolio"), label: t("portfolio") },
            { to: "payments", label: t("payments")}
        ]},
        hasBrands &&
        { to: "trademarks", icon: <IconTradeMark />, label: t("trademarks"), subitems: [
            { to: portfolioLink("/trademarks/portfolio", "portfolio"), label: t("portfolio") },
            { to: "countries", label: t("country-map") },
            { to: "data", label: t("data-wizard") },
            hasCosts &&
            { to: "costs", label: t("costs") },
            { to: "products", label: t("product-mapping") },
        ]},
        isDev &&
        { to: "designs", icon: <IconPhoto />, label: t("designs") },
        { to: "products", icon: <IconBox />, label: t("products") }, 
        hasTasks &&
        { to: "tasks", icon: <IconCalendar />, label: t("tasks"), badge: <TasksBadge {...{smallMenu}} /> },
        hasDocuments &&
        { to: "documents", icon: <IconDocumentText />, label: t("documents") },
        hasAnnuities &&
        { to: "renewals", icon: <IconArrowPath />, label: t("renewals"), badge: <RenewalsBadge {...{smallMenu}} />, subitems: [
            // { to: "maintenances", label: t("open-maintenances") }, // TODO: badges
            // { to: "history", label: t("past-maintenances") },
            { to: "maintenances", label: t("undecided-maintenances") }, // TODO: badges
            { to: "history", label: t("decided-maintenances") },
            { to: "documents", label: t("documents") }, // TODO badges
            isEditUser &&
            { to: "master-data", label: t("master-data")},
            isAnnuityAdmin &&
            { to: "settings", label: t("admin-settings")},
        ]},
        isDev &&
        { to: "licenses", icon: <IconCheckBadge />, label: t("licenses")},
        //{ },
        //{ to: "settings", icon: <IconCog />, label: t("settings"), enabled: true },
    ].filter(Boolean)

    const displayName = (team?.displayName && team.displayName !== "")
            ? team.displayName
            : (team?.realm ?? '');
    const avatar = images.find(i => i.entity === "realm" && i.entityId === realmId);

    return (
        /* @ts-ignore */
        <HelmetProvider >
            {/* @ts-ignore */}
            <Helmet>
                <title>Patent Cockpit</title>
                {hasTracking && <script type="text/javascript">{` 
                  (function (c, l, a, r, i, t, y) { 
                    // tracking code for Clarity
                    c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
                    t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
                    y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
                  })(window, document, "clarity", "script", "m7ss64ema3");
                `}</script>}
            </Helmet>

            <div className={clsx(
                "sm:grid ", smallMenu ? "grid-cols-[3.5rem_1fr]" : "grid-cols-[15rem_1fr]", 
                "h-screen w-screen"
            )}>
                <input type="checkbox" id={burgerMenuId} name={burgerMenuId} className="hidden peer" />
                <div id="side-menu" className={clsx(
                    "max-sm:hidden peer-checked:block",
                    "overflow-hidden",
                    "bg-pcx-900 text-pcx-100 sm:min-h-screen sm:flex flex-col pt-4",
                    smallMenu && "hover:w-[15rem] z-50 shadow-lg group"
                )}>
                    <div className="overflow-auto flex flex-col h-full">
                        <div className="flex flex-row gap-1 w-full pr-3">
                            <Link to="/" className="flex flex-row gap-2 items-center px-3 py-2">
                                {avatar?.url
                                    ? <div className="h-6 w-6 sm:h-8 sm:w-8 inline-flex items-center">
                                        <img className="mx-auto" src={avatar.url} alt="Team Logo" />
                                    </div>
                                    : <div className="sm:text-3xl text-3xl text-center uppercase">{displayName[0]}</div>}
                                <div className={clsx(smallMenu ? "group-hover:block hidden" : "block")}>
                                    {displayName}
                                </div>
                            </Link>
                            {/* <div className="grow" />
                            <SwitchTeamsButton {...{smallMenu}} /> */}
                            <label htmlFor={burgerMenuId} className="sm:hidden self-center">
                                <IconBurgerMenu />
                            </label>
                        </div>

                        <div className="h-4" />

                        {navigationItems.map((item, index) =>
                            <MainNavigationLink key={index} {...item} smallMenu={smallMenu} />
                        )}

                        <div className="grow" />

                        <div className="px-3 pb-6 flex flex-row justify-between items-center">
                            <div className={clsx(smallMenu ? "group-hover:block hidden" : "block", "pl-8")}>
                                <ModernVersion />
                            </div>
                            <button
                                title={smallMenu ? t('keep-open') : t('collapse-menu')}
                                className={clsx(smallMenu ? "group-hover:self-end" : "self-end", "max-sm:hidden")}
                                onClick={() => setSmallMenu(!smallMenu)}
                            >
                                <PiSidebarFill className="h-6 w-6 mt-auto" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="bg-pcx-100 min-w-0 flex flex-col overflow-hidden">
                    <MessagesDisplay />
                    <TopLine />
                    <div className="overflow-auto grow flex flex-col">
                        <Outlet />
                    </div>
                </div>
            </div>
        </HelmetProvider>
    )
}

function MainNavigationLink({to, icon, label, badge = null, subitems = [] as {to: string, label: string, enabled?: boolean}[], smallMenu}) {
    const [unfolded, setUnfolded] = useState(false)
    const _subitems = subitems.filter(Boolean)

    return <>
        <div className="relative group/nav">
            <NavLink to={to} onClick={() => setUnfolded(true)} className={({ isActive }) => clsx(
                "px-3 py-2 w-full flex flex-row gap-2 h-10 cursor-pointer focus:ring-0 focus:outline-none",
                isActive 
                    ? "peer bg-pcx-800 hover:bg-pcx-600 group-hover/nav:bg-pcx-600 focus:bg-pcx-600 group-focus-within/nav:bg-pcx-600" 
                    : "hover:bg-pcx-700  group-hover/nav:bg-pcx-700 focus:bg-pcx-700 group-focus-within/nav:bg-pcx-700"
            )}>
                {icon} <span className={clsx(smallMenu ? "hidden group-hover:block" : "block")}>{label}</span> {badge}
            </NavLink>
            {_subitems.length > 0 && 
                <button onClick={() => setUnfolded(!unfolded)} className={clsx(
                    smallMenu ? "hidden group-hover:block" : "block",
                    "absolute right-0 inset-y-0 pr-3 focus:ring-0 focus:outline-none bg-inherit xhover:bg-pcx-700",
                )}>
                    <IconChevronDown className={clsx("h-5 w-5 pb-0.5", unfolded && "rotate-180")} />
                </button>}
        </div>
        {unfolded &&
            <div>
                {_subitems
                    .map(({ to: ito, label }, index) =>
                        <NavLink key={index} to={ito.startsWith('/') ? ito : `${to}/${ito}`} className={({ isActive }) => clsx(
                            smallMenu ? "hidden group-hover:block" : "block",
                            "py-1.5 last:pb-2 pl-11 text-sm focus:ring-0 focus:outline-none",
                            isActive ? "bg-pcx-700 hover:bg-pcx-600 focus:bg-pcx-600" : "peer:bg-pcx-800 hover:bg-pcx-700 focus:bg-pcx-700",
                        )}>
                            {label}
                        </NavLink>
                    )}
            </div>}
    </>
}

function SearchButton() {
    const location = useLocation()
    const defaultSearchType = location.pathname.startsWith("/documents") ? "document" : "ip"
    //console.log({defaultSearchType, location})
    return <DefaultSearchButton key={defaultSearchType} {...{defaultSearchType}} />
}

export function TopLine() {
    const {t} = useTranslation()
    return (
        <Disclosure>
            <div className="flex flex-row gap-2 items-center  py-1.5 pl-4 pr-4 border-b border-gray-200">
                <div className="hidden lg:flex flex-row gap-2 mr-auto">
                    <FilterStuff />
                </div>
                <div className="grow" />
                <SearchButton />
                <div className="sm:h-6 sm:w-px sm:bg-gray-900/10" />
                <UserMenu />
                <label htmlFor={burgerMenuId} className="sm:hidden">
                    <IconBurgerMenu />
                </label>
            </div>
            <div className="flex flex-row flex-wrap lg:hidden px-4 py-1.5 border-b border-gray-200">
                <FilterStuff />
            </div>
            <DisclosurePanel className="border-t border-gray-200 pt-4 pb-2 px-4 shadow-md bg-pcx-200 ">
                <div className="text-sm flex flex-row flex-wrap gap-y-10 gap-x-10 pt-0 pb-10">
                    <FilterSettings />
                </div>

                <DisclosureButton className="text-xs flex flex-row items-center gap-1 pl-1 pr-2 py-px btn-tertiary">
                    <IconChevronUp className="h-4 w-4" /> {t('fold-up')}
                </DisclosureButton>
            </DisclosurePanel>
        </Disclosure>
    )
}

function FilterStuff() {
    return <>
        <Disclosure.Button>
            <AdjustmentsHorizontalIcon
                className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
                title="Filter Settings"
            />
        </Disclosure.Button>
        <div className="flex flex-row gap-1">
            <FilterRibbons />
        </div>
    </>
}

function FilterRibbons() {
    const { activeFilters } = useFilter();
    return <>
        <OnlyActiveRibbon />
        {activeFilters
            .filter((f) => f.isActive && f.name !== active_only_filter)
            .map(({ display }, index) => (
                <FilterRibbon key={index} {...{ display, index }} />
            ))}
    </>
}

function FilterSettings() {
    const { activeFilters } = useFilter();
    return <>
        {activeFilters.map((filter, index) => (
            <FilterDisplay key={index} {...{ filter, index }} />
        ))}
        <AddFilter />
    </>
}


export function TestLayout() {
    return (
        <div className="h-screen w-screen">
            <div className="h-full w-full grid grid-cols-[15rem_1fr]">
                <div className="bg-green-300 flex flex-col font-bold overflow-hidden">
                    <div className="min-h-full overflow-auto">
                        <div>Sidemenu</div>
                        <div className="h-[80vh] shrink-0" />
                        <div>Button</div>
                    </div>
                </div>

                <div className="overflow-hidden flex flex-col">
                    <div className="bg-yellow-200">Top Line</div>
                    <div className="overflow-auto">
                        Main Area
                        <div className="h-[120vh] w-[120vw] bg-warn-300 p-10">
                            <div className="bg-blue-200 w-full h-full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}