import { useState } from "react";
import { get_realm_statistics } from "../dennemeyer_backend";
import DateRangePicker from "../../components/input/DateRangePicker";
import clsx from "clsx";
import _ from "lodash";

export default function RenwalsStatistics() {

    const [start, setStart] = useState('2024-01-01')
    const [end, setEnd] = useState('2024-12-31')

    const [statistics, setStatistics] = useState(undefined)

    async function onClick() {
        const stats = await get_realm_statistics(start, end)
        setStatistics(stats)
        console.log({ stats })
    }

    return (
        <>
            <div className="flex flex-row items-baseline gap-4 ">
                <label className="label">Range</label>
                <DateRangePicker className="py-1 px-1.5" {...{minDate: start, setMinDate: setStart, maxDate: end, setMaxDate: setEnd, name: ''}} />
                <button className="btn-primary" onClick={onClick}>Get Statistics</button>
            </div>

            <div className="pt-4">
                {statistics === undefined
                    ? <p>Click the button to get the statistics.</p>
                    : <StatisticsTable statistics={statistics} />
                }
            </div>
        </>
    );
}

interface Stats {
    droppedFees: number
    end: string
    openFees: number
    paidFees: number
    realm: string
    start: string
    totalFees: number
}

const fields = [
    'realm',
    'openFees',
    'droppedFees',
    'paidFees',
    'totalFees'
]
const headerClass = "px-2.5 py-2 text-pcx-600 text-sm font-semibold uppercase tracking-wider whitespace-nowrap"

function StatisticsTable({ statistics }: { statistics: Stats[] }) {
    const [selected, setSelected] = useState(_.fromPairs(statistics.map(s => [s.realm, true])))

    const total = statistics.reduce((acc, stats) => {
        if (!selected[stats.realm]) return acc

        fields.forEach(field => {
            acc[field] += stats[field]
        })
        return acc
    }, _.fromPairs(fields.map(field => [field, 0])))

    const isAll = _.values(selected).every(v => v)

    return (
        <table>
            <thead>
                <tr className="border-b-2 border-pcx-300">
                    {fields.map(field =>
                        <th key={field} className={clsx('text-left', headerClass)}>
                            {field === 'realm' && <input type="checkbox" className="form-checkbox inline-block mr-2"
                                checked={isAll}
                                onChange={() => setSelected(_.fromPairs(statistics.map(s => [s.realm, !isAll])))}
                            />}
                            {_.snakeCase(field).replace('_', ' ')}
                        </th>)}
                    <th className={clsx('text-left', headerClass, 'text-slate-400')}>
                        Difference
                    </th>
                </tr>
            </thead>
            <tbody>
                {statistics.map((stats, index) => {
                    return <tr key={index}>
                        {fields.map(field =>
                            <td key={field} className={clsx(field === 'realm' ? 'text-left' : 'text-right', 'px-2.5')}>
                                {field === 'realm' && 
                                    <input type="checkbox" className="form-checkbox inline-block mr-2"
                                        checked={selected[stats.realm]}
                                        onChange={() => setSelected({ ...selected, [stats.realm]: !selected[stats.realm] })} 
                                    />}
                                {stats[field]}
                            </td>)}
                        <td className="text-right px-2.5 text-slate-400">
                            {stats.totalFees - stats.droppedFees - stats.paidFees - stats.openFees}
                        </td>
                    </tr>
                })}
            </tbody>
            <tfoot>
                <tr className="border-t-2 border-pcx-300 font-semibold text-pcx-600">
                    {fields.map(field =>
                        <td key={field} className={clsx(field === 'realm' ? 'text-left pl-9' : 'text-right px-2.5')}>
                            {field === 'realm' ? 'Total' : total[field]}
                        </td>)}
                    <td className="text-right px-2.5 text-slate-400">
                        {total.totalFees - total.droppedFees - total.paidFees - total.openFees}
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}